import React, { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { useStaticQuery, graphql, Link } from 'gatsby'
import { DateTime } from "luxon"
import { localize } from "../lib/helpers"
import { window } from 'browser-monads'
import smoothscroll from 'smoothscroll-polyfill'

import Logo from "../assets/svg/logo.svg"
import CTAarrow from "../assets/svg/cta_arrow.svg"

const Footer = ({ lang }) => {
  const { sanityContact } = useStaticQuery(
    graphql`
      query {
        sanityContact {
          instagram
          twitter
          behance
          spotify
          rights1
          _rawRights
          privacy {
            asset {
              url
            }
          }
        }
      }
    `
  )

  const
    { t, i18n } = useTranslation('common'),
    { instagram, twitter, behance, spotify, _rawCta, rights1, _rawRights, privacy } = localize(sanityContact, [lang]),
    [time, setTime] = useState(DateTime.now().setZone("America/Mexico_City").setLocale("en").toFormat("tt"))

  const scrollTop = () => {
    window.__forceSmoothScrollPolyfill__ = true;
    smoothscroll.polyfill()
    window.scrollTo({top: 0, behavior: 'smooth'});
  }

  useEffect(() => {
    const newTime = setInterval(() => {
      setTime(DateTime.now().setZone("America/Mexico_City").setLocale("en").toFormat("tt"))
    }, 1000);

    return () => {
      clearInterval(newTime)
    }

  }, [time])

  return (
    <footer className="grid-16">
      <div className="location">
        <p className="city">{t("mx")}</p>
        <p className="hour">{time}</p>
      </div>

      <div className="socials">
        <p className="city">{t("ways")}</p>
        <a target="__blank" href={instagram}>Instagram</a>
        <a target="__blank" href={twitter}>Twitter</a>
        <a target="__blank" href={behance}>Behance</a>
        <a target="__blank" href={spotify}>Spotify</a>
        <a target="__blank" href="https://wa.me/19713006162">Whatsapp</a>
      </div>

      <Link className="cta" to={`/${i18n.language}/contact`}>{t("footerCTA")}<CTAarrow className="arrow"/></Link>

      <Link to={`/${i18n.language}`} className="footer-logo"><Logo/></Link>

      <button title="scroll to top" type="button" onClick={scrollTop} onKeyDown={scrollTop}><CTAarrow className="topArrow"/></button>

      <div className="privacy-rights">
        <a className="privacy" rel="noopener noreferrer" href={privacy.asset.url} target="_blank">{t("privacy")}</a>
        <p className="rights">{rights1}</p>
        <p className="rights">{_rawRights}</p>
      </div>


    </footer>
  )
}

export default Footer
