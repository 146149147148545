import React from "react"

const Quote = ({ quote }) => (
  <section data-sal='fade' data-sal-duration='750' data-sal-easing='ease' className="quote-container grid-16">
    <q className="M60-regular">{quote.quote}</q>
    <p className="author M16-regular">- {quote.author}</p>
  </section>
)

export default Quote
