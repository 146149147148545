import React from "react"
import PropTypes from "prop-types"

import "normalize.css"
import "../styles/custom.scss"
import "../styles/media.scss"

import Header from "./header"
import Footer from "./footer"

const Layout = ({ children, lang }) => {
  return (
    <>
      <Header lang={lang} />
      <main>
        {children}
      <Footer lang={lang} />
      </main>
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
