import React from "react"
import { graphql } from "gatsby"
import { localize } from "../lib/helpers"

import Layout from "../components/layout"
import Seo from "../components/seo"


import Quote from "../components/Contact/quote"
import FormClientsEn from "../components/Contact/formClientsEn"
import FormClientsEs from "../components/Contact/formClientsEs"

const Clients = ({ data, pageContext, location }) => {
  const
    { _rawTitle, _rawQuote, _rawSectionTitle, _rawFields, _rawSection2Title,
      _rawSection2Subtitle, _rawSection3Title, _rawSection3Subtitle } = localize(data.sanityCustomers, [pageContext.language]),
    { _rawServices } = localize(data.sanityServices, [pageContext.language]);

  return (
    <Layout lang={pageContext.language}>
      <Seo title={_rawTitle} lang={pageContext.language} location={location} />
      <div id="clients">
        <Quote quote={_rawQuote} />

        {pageContext.language === "en" ? (
          <FormClientsEn title={_rawSectionTitle} fields={_rawFields} title2={_rawSection2Title} subtitle2={_rawSection2Subtitle}
          title3={_rawSection3Title} subtitle3={_rawSection3Subtitle} services={_rawServices}/>
        ) : (
          <FormClientsEs title={_rawSectionTitle} fields={_rawFields} title2={_rawSection2Title} subtitle2={_rawSection2Subtitle}
            title3={_rawSection3Title} subtitle3={_rawSection3Subtitle} services={_rawServices}/>
        )}
      </div>
    </Layout>
  )
}

export const query = graphql`
  query ClientsPage {
    sanityCustomers {
      _rawTitle
      _rawQuote
      _rawSectionTitle
      _rawFields
      _rawSection2Title
      _rawSection2Subtitle
      _rawSection3Title
      _rawSection3Subtitle
    }
    sanityServices {
      _rawServices
    }
  }
`

export default Clients
