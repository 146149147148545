import React from "react"

const ServiceCategory = ({ service }) => {
  return (
    <div className="service-category">
      <h5 className="M24-light">{service.name}</h5>

      {service?.list?.map((elem, i) => (
        <fieldset key={`listelem-${i}`}>
          <input type="checkbox" name={elem} value={elem}/>
          <label className="M16-regular" htmlFor={elem}>{elem}</label>
        </fieldset>
      ))}
    </div>
  )
}

export default ServiceCategory
