import React from "react"

import Arrow from "../../assets/svg/cta_arrow.svg"
import ServiceCategory from "./serviceCategory"

const FormClientsEn = ({ title, fields, title2, subtitle2, services, title3, subtitle3 }) => {
  return (
    <form className="grid-16" name='Latente-Clients-En' method='post'
      action="/en/success" data-netlify='true' data-netlify-honeypot='bot-field' autoComplete='off'>
      <input type='hidden' name='bot-field'/>
      <input type='hidden' name='form-name' value='Latente-Clients-En'/>

      <h3 data-sal='fade' data-sal-duration='750' data-sal-easing='ease' className="M16-regular form-title">{title}</h3>
      {fields?.map(field => (
        <fieldset data-sal='fade' data-sal-duration='750' data-sal-easing='ease' className="field-container" key={field._key}>
          <label htmlFor={field.name}>{field.label}</label>
          <input className="M24-light" type={field.type} name={field.name} placeholder={field.placeholder} required={field.required}/>
        </fieldset>
      ))}

      <h3 data-sal='fade' data-sal-duration='750' data-sal-easing='ease' className="M16-regular form-title">{title2}</h3>
      <h4 data-sal='fade' data-sal-duration='750' data-sal-easing='ease' className="form-subtitle">{subtitle2}</h4>
      <div data-sal='fade' data-sal-duration='750' data-sal-easing='ease' className="services-container">
        {services?.map(service => (
          <ServiceCategory key={service._key} service={service}/>
        ))}
      </div>

      <h3 data-sal='fade' data-sal-duration='750' data-sal-easing='ease' className="M16-regular form-title">{title3}</h3>
      <h4 data-sal='fade' data-sal-duration='750' data-sal-easing='ease' className="form-subtitle">{subtitle3}</h4>

      <textarea data-sal='fade' data-sal-duration='750' data-sal-easing='ease' className="M24-light" name="message" placeholder="Hello,"></textarea>

      <button data-sal='fade' data-sal-duration='750' data-sal-easing='ease' type="submit" className="cta">Submit <Arrow className="arrow"/></button>
    </form>
  )
}

export default FormClientsEn
